.glow {
  display: inline-block;
  height: 36vh;
  width: 25rem;
  border: 5px dashed rgba(18, 102, 100, 0.21);
  border-radius: 17px;
  font-family: "Lato";
  padding: 5vh;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}
