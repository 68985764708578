.teacher {
  display: "inline-flex";
  align-items: "center";
  font-size: 27;
  color: #454545;
  width: 20vw;
}

.teacher-border {
  cursor: pointer;
  border-radius: 6px;
  border: none;
  padding: 20px;
  box-shadow: 0px 0px 0px 1px rgba(18, 102, 100, 0.44);
  width: 22vw;
}


nav.react-contextmenu {
  position: fixed;
  width: 200px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 99;
  box-shadow: 0 0 5px rgba(0,0,0,.05);
}
.react-contextmenu .react-contextmenu-item:hover{
background: #cccccc;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
background-color: #f1f1f1;
}

.react-contextmenu .react-contextmenu-item{
font-size: 18px;
display: block;
text-decoration: none;
padding: 10px 15px;
cursor: pointer;
user-select: none;
transition: .2s;
position: relative;
margin-bottom: 2px;
font-weight: 500;
display: flex;
align-items: center;
outline: none;
}
.react-contextmenu-item span{
margin-left: 10px;
}
.react-contextmenu-item svg{
font-size: 20px;
}
