.background {
  background-color: #ecf3f3;
  width: 83%;
  z-index: 1;
  height: 20vh;
  position: fixed;
  bottom: 0;
  right: 2px;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.16);
}

#progress {
  border-right: 3px solid red;
  height: 100%;
  z-index: 3;
  position: fixed;
}

#speed{
  z-index: 4;
  position: absolute;
  font-size: 2vh;
  color: #126462;
  font-weight: bold;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

#speed:hover{
  background-color: rgba(0, 0, 0, 0.16);
}

.line {
  height: 0.8vh;
  width: 1px;
  background-color: #707070;
  bottom: 0;
  position: relative;
  display: block;
  float: left;
}
.line-mm {
  height: 0.8vh;
  width: 1px;
  background-color: #dedede;
  top: 0;
  position: relative;
  display: block;
  float: left;
}

.time-text {
  display: block;
  font-size: 12px;
}

.p-no {
  width: 2.88vh;
  height: 2.4vh;
  line-height: 2.4vh;
  font-weight: bold;
  border-radius: 3px;
  background: #126462;
  color: white;
  font-size: 1.4vh;
  text-align: center;
  position: absolute;
  top: 12.5vh;
  justify-content: center;
  cursor: pointer;
}

@import url(//db.onlinewebfonts.com/c/e16d93211abd23ce0cfc21ec1ae2f881?family=SBL+Hebrew);
.paragraph {
  font-family: "SBL Hebrew";
  color: #126462;
  font-size: 3vh;
  padding: 10px;
  padding-top: 25px;
}
p, b{
  font-family: "SBL Hebrew"
}

.paragraph.last {
  margin-bottom: 22vh;
}

small {
  font-family: "SBL Hebrew";
}

.paragraph:hover {
  background-color: #ecf3f3;
  border-radius: 12px;
  cursor: pointer;
}
.first{
  font-family: "SBL Hebrew";
  font-size:250%;
  line-height: 25px;
  color: #126462;
}
.paragraph.current {
  background-color: #ecf3f3;
  border-radius: 12px;
  cursor: pointer;
}

.save {
  width: 11.1vh;
  height: 4.72vh;
  border-radius: 9px;
  background: #ecf3f3;
  border: 1px solid #126462;
  line-height: 4.3vh;
  text-align: center;
  color: #126462;
  font-size: 2.5vh;
  display: inline-block;
  cursor: pointer;
}