@import url(//db.onlinewebfonts.com/c/e16d93211abd23ce0cfc21ec1ae2f881?family=SBL+Hebrew);
body {
  margin: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.custom-modal-style {
  width: 90vw;
  max-width: 90vw;
  }
  

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.login-field {
  height: 6.5vh;
  width: 18vw;
  background-color: rgba(18, 102, 100, 0.04);
  background-image: url(/static/media/user.0f01dee8.svg);
  padding-left: 2.5vw;
  background-size: 1.6vh;
  background-position: 2vh 2vh;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0);
  margin: 2vh 0px;
  font-size: 0.9vw;
  color:rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.login-field:focus {
  outline: none;
}


.login-field.pwd {
    background-image: url(/static/media/padlock.49a9dded.svg);
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.sidebar-item {
  color: #126664;
  font-weight: bold;
}

#bottom {
  position: absolute;
  bottom: 10px;
  -webkit-align-self: center;
          align-self: center;
  text-align: center;
  width: 100%;
}

.teacher {
  display: "inline-flex";
  -webkit-align-items: "center";
          align-items: "center";
  font-size: 27;
  color: #454545;
  width: 20vw;
}

.teacher-border {
  cursor: pointer;
  border-radius: 6px;
  border: none;
  padding: 20px;
  box-shadow: 0px 0px 0px 1px rgba(18, 102, 100, 0.44);
  width: 22vw;
}


nav.react-contextmenu {
  position: fixed;
  width: 200px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 99;
  box-shadow: 0 0 5px rgba(0,0,0,.05);
}
.react-contextmenu .react-contextmenu-item:hover{
background: #cccccc;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
background-color: #f1f1f1;
}

.react-contextmenu .react-contextmenu-item{
font-size: 18px;
display: block;
text-decoration: none;
padding: 10px 15px;
cursor: pointer;
-webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
transition: .2s;
position: relative;
margin-bottom: 2px;
font-weight: 500;
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
        align-items: center;
outline: none;
}
.react-contextmenu-item span{
margin-left: 10px;
}
.react-contextmenu-item svg{
font-size: 20px;
}

.divider {
  border-top: 1px solid #707070;
  opacity: 0.5;
}

.title {
  color: #5a5a5a;
  font-size: 1.8vw;
  font-weight: 300;
  padding: 5px 6px;
  display: inline-block;
}
.title-no-hover {
  color: #5a5a5a;
  font-size: 1.8vw;
  font-weight: 300;
  padding: 5px 6px;
  display: inline-block;
}

.title:hover {
  background-color: #ecf3f3;
  cursor: pointer;
  border-radius: 6px;
}
.icon {
  color: #5a5a5a;
  font-size: 1.8vw;
  font-weight: 300;
  margin: 0px 8px 7px 8px;
  display: inline-block;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 230px);
  grid-column-gap: 26px;
  -webkit-column-gap: 26px;
          column-gap: 26px;
}

.drag-drop {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25vh;
  margin-top: -12vh;
}
.grid_teacher {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0px, 23vw));
  grid-column-gap: 26px;
  -webkit-column-gap: 26px;
          column-gap: 26px;
  grid-row-gap: 26px;
  row-gap: 26px;
}

.add_teacher {
  cursor: pointer;
  position: absolute;
  bottom: 50px;
  right: 50px;
  height: 7.5vh;
  width: 7.5vh;
  border-radius: 50%;
  background-color: #126664;
}

.add_icon {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 3vh;
  width: 3vh;
}

.dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  border-color: "#126462"; /*set the color you want here*/
}

.teacher-item-tile {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 80px;
  padding: 0px 20px;
  font-size: 23px;
  color: #454545;
  border-bottom: 1px solid rgba(112,112,112,0.11);
}

.teacher-item-tile:hover {
  background-color: rgba(112,112,112,0.11);
}

.teacher-item-tile.clicked{
  background-color: #126664;
  color: white;
}

.glow {
  display: inline-block;
  height: 36vh;
  width: 25rem;
  border: 5px dashed rgba(18, 102, 100, 0.21);
  border-radius: 17px;
  font-family: "Lato";
  padding: 5vh;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: center;
          align-self: center;
}

.folder {
  display: "inline-flex";
  -webkit-align-items: "center";
          align-items: "center";
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  font-size: 27;
  max-width: 140px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.vertical {
    border-bottom: 1px solid #c9c9c9;
    padding: 20px 5px;
    margin-left: 10px;
    margin-right: 100px;
}

.border {
    border-radius: 6px;
    border: none;
    box-shadow: 0px 0px 0px 1px rgba(18,102,100,0.44);
    padding: 20px 25px;
    margin-top: 40px;
  }
.background {
  background-color: #ecf3f3;
  width: 83%;
  z-index: 1;
  height: 20vh;
  position: fixed;
  bottom: 0;
  right: 2px;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.16);
}

#progress {
  border-right: 3px solid red;
  height: 100%;
  z-index: 3;
  position: fixed;
}

#speed{
  z-index: 4;
  position: absolute;
  font-size: 2vh;
  color: #126462;
  font-weight: bold;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

#speed:hover{
  background-color: rgba(0, 0, 0, 0.16);
}

.line {
  height: 0.8vh;
  width: 1px;
  background-color: #707070;
  bottom: 0;
  position: relative;
  display: block;
  float: left;
}
.line-mm {
  height: 0.8vh;
  width: 1px;
  background-color: #dedede;
  top: 0;
  position: relative;
  display: block;
  float: left;
}

.time-text {
  display: block;
  font-size: 12px;
}

.p-no {
  width: 2.88vh;
  height: 2.4vh;
  line-height: 2.4vh;
  font-weight: bold;
  border-radius: 3px;
  background: #126462;
  color: white;
  font-size: 1.4vh;
  text-align: center;
  position: absolute;
  top: 12.5vh;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
}
.paragraph {
  font-family: "SBL Hebrew";
  color: #126462;
  font-size: 3vh;
  padding: 10px;
  padding-top: 25px;
}
p, b{
  font-family: "SBL Hebrew"
}

.paragraph.last {
  margin-bottom: 22vh;
}

small {
  font-family: "SBL Hebrew";
}

.paragraph:hover {
  background-color: #ecf3f3;
  border-radius: 12px;
  cursor: pointer;
}
.first{
  font-family: "SBL Hebrew";
  font-size:250%;
  line-height: 25px;
  color: #126462;
}
.paragraph.current {
  background-color: #ecf3f3;
  border-radius: 12px;
  cursor: pointer;
}

.save {
  width: 11.1vh;
  height: 4.72vh;
  border-radius: 9px;
  background: #ecf3f3;
  border: 1px solid #126462;
  line-height: 4.3vh;
  text-align: center;
  color: #126462;
  font-size: 2.5vh;
  display: inline-block;
  cursor: pointer;
}
