.login-field {
  height: 6.5vh;
  width: 18vw;
  background-color: rgba(18, 102, 100, 0.04);
  background-image: url(../components/Icons/user.svg);
  padding-left: 2.5vw;
  background-size: 1.6vh;
  background-position: 2vh 2vh;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0);
  margin: 2vh 0px;
  font-size: 0.9vw;
  color:rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.login-field:focus {
  outline: none;
}


.login-field.pwd {
    background-image: url(../components/Icons/padlock.svg);
}
