.grid_teacher {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0px, 23vw));
  column-gap: 26px;
  row-gap: 26px;
}

.add_teacher {
  cursor: pointer;
  position: absolute;
  bottom: 50px;
  right: 50px;
  height: 7.5vh;
  width: 7.5vh;
  border-radius: 50%;
  background-color: #126664;
}

.add_icon {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3vh;
  width: 3vh;
}

.dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  border-color: "#126462"; /*set the color you want here*/
}

.teacher-item-tile {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0px 20px;
  font-size: 23px;
  color: #454545;
  border-bottom: 1px solid rgba(112,112,112,0.11);
}

.teacher-item-tile:hover {
  background-color: rgba(112,112,112,0.11);
}

.teacher-item-tile.clicked{
  background-color: #126664;
  color: white;
}
