.folder {
  display: "inline-flex";
  align-items: "center";
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  font-size: 27;
  max-width: 140px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.vertical {
    border-bottom: 1px solid #c9c9c9;
    padding: 20px 5px;
    margin-left: 10px;
    margin-right: 100px;
}

.border {
    border-radius: 6px;
    border: none;
    box-shadow: 0px 0px 0px 1px rgba(18,102,100,0.44);
    padding: 20px 25px;
    margin-top: 40px;
  }