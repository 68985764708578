.divider {
  border-top: 1px solid #707070;
  opacity: 0.5;
}

.title {
  color: #5a5a5a;
  font-size: 1.8vw;
  font-weight: 300;
  padding: 5px 6px;
  display: inline-block;
}
.title-no-hover {
  color: #5a5a5a;
  font-size: 1.8vw;
  font-weight: 300;
  padding: 5px 6px;
  display: inline-block;
}

.title:hover {
  background-color: #ecf3f3;
  cursor: pointer;
  border-radius: 6px;
}
.icon {
  color: #5a5a5a;
  font-size: 1.8vw;
  font-weight: 300;
  margin: 0px 8px 7px 8px;
  display: inline-block;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 230px);
  column-gap: 26px;
}

.drag-drop {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25vh;
  margin-top: -12vh;
}